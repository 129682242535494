<template>
  <div class="pageWithTable">
    <bm-cardTabList
      :list="[
        {
          path: '/product/partnerProduct/list',
          name: '外接商品'
        },
        {
          path: '/product/partnerProduct/checkCode/list',
          name: '核销码池'
        }
      ]"
      current="核销码池"
    ></bm-cardTabList>

    <el-card class="searchContainer">
      <el-form inline>
        <el-form-item label="款式名称">
          <el-input
            v-model.trim="filter.name"
            placeholder="请输入款式名称"
            @keyup.enter.native="handleSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="款式ID">
          <el-input v-model.trim="filter.id" placeholder="请输入款式ID" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="handleReset">重置</el-button>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="tableContainer">
      <div class="header" slot="header">
        <span class="title">核销码列表</span>
        <el-button size="mini" type="success" @click="handleAdd">添加文本核销码</el-button>
      </div>
      <el-table :data="list" fit center v-loading="loading">
        <el-table-column prop="id" label="id" align="center"> </el-table-column>
        <el-table-column prop="name" label="名称" align="center">
          <template slot-scope="scope">
            <el-popover placement="top-start" trigger="hover">
              <img :src="scope.row.imageUrl | thumbSmall" style="max-width: 200px; max-height: 200px" />
              <el-tag slot="reference" type="info" effect="dark">{{ scope.row.name }}</el-tag>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="库存" align="center">
          <template slot-scope="scope">
            <el-popover placement="top-start" trigger="hover">
              <div>总库存：{{ scope.row.checkCodePoolStateVO.totalCount }}</div>
              <div>已 售：{{ scope.row.checkCodePoolStateVO.sellCount }}</div>
              <div>已核销：{{ scope.row.checkCodePoolStateVO.checkCount }}</div>
              <div>已绑定：{{ scope.row.checkCodePoolStateVO.latchCount }}</div>
              <div>退 款：{{ scope.row.checkCodePoolStateVO.refundCount }}</div>

              <el-tag slot="reference" type="info" effect="dark">总库存：{{ scope.row.totalCount }}</el-tag>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="价格" align="center">
          <template slot-scope="scope">
            <el-popover placement="top-start" trigger="hover">
              <div>原价：{{ scope.row.originalPrice | formatPrice }}</div>
              <div>售价：{{ scope.row.salePrice | formatPrice }}</div>
              <el-tag slot="reference" type="info" effect="dark">售价：{{ scope.row.salePrice | formatPrice }}</el-tag>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="tenantId" label="状态" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" type="info">审核中</el-tag>
            <el-tag v-if="scope.row.status === 1" type="danger">失败</el-tag>
            <el-tag v-if="scope.row.status === 2" type="success">成功/待绑定</el-tag>
            <el-tag v-if="scope.row.status === 3">已绑定</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="操作" width="280px" align="center" fixed="right">
          <template slot-scope="scope">
            <el-dropdown @command="handleEdit($event, scope.row)">
              <el-button type="primary" icon="el-icon-edit" plain size="mini">编辑</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="handleEditInfo" @click="handleEditInfo(scope.row)"
                  >编辑商品信息</el-dropdown-item
                >
                <el-dropdown-item command="handleEditFile" @click="handleEditFile(scope.row)"
                  >编辑核销码信息</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              @click="handleUpdateCheckedCode(scope.row)"
              type="warning"
              plain
              size="mini"
              icon="el-icon-key"
              style="margin: 0 10px"
              >核销</el-button
            >
            <el-popconfirm @confirm="handleDelete(scope.row)" title="确定要删除吗？">
              <el-button slot="reference" type="danger" icon="el-icon-delete" plain size="mini">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          :layout="pageLayout"
          :total="page.total"
          :page-sizes="sizeList"
          :page-size="page.size"
          :current-page="page.page"
          @size-change="onSizeChange"
          @current-change="onPageChange"
        ></el-pagination>
      </div>
    </el-card>

    <el-dialog :visible.sync="isShowAddDialog" title="新增文本核销码" :close-on-click-modal="false">
      <el-form label-width="10em">
        <el-form-item label="商家">
          <div class="businessTagList">
            <el-tag
              closable
              class="item"
              v-for="(i, n) in addForm.businessList"
              :key="n"
              @close="onBusinessTagClose(i)"
              style="margin-right: 5px"
              >{{ i.name }}</el-tag
            >
            <el-tag @click="isShowPickBusiness = true" type="success">添加商家</el-tag>
          </div>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
            action=""
            :show-file-list="false"
            :http-request="onImageUpload"
            :accept="$store.getters['upload/fileAccept'].image"
          >
            <img v-if="addForm.imageUrl" :src="addForm.imageUrl | thumbMedium" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="名称">
          <el-input
            v-model.trim="addForm.name"
            placeholder="请输入名称"
            maxlength="40"
            show-word-limit
            class="middleInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="售卖价格">
          <bm-inputPrice v-model="addForm.salePrice"></bm-inputPrice>
        </el-form-item>
        <el-form-item label="原价">
          <bm-inputPrice v-model="addForm.originalPrice" :min="addForm.salePrice"></bm-inputPrice>
        </el-form-item>
        <el-form-item label="核销码文件">
          <el-upload
            ref="addDialogUpload"
            action=""
            :accept="$store.getters['upload/fileAccept'].excel"
            :auto-upload="false"
            :limit="1"
            :on-remove="onExcelRemove"
            :on-change="onExcelUpload"
          >
            <div slot="tip" class="el-upload__tip">只能选择excel文件，且不超过10M</div>
            <el-button size="mini" type="success" icon="el-icon-plus">点击选择</el-button>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button @click="handleSubmitAdd" :disabled="!isReadySubmitAdd" type="primary">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="isShowEditInfoDialog" title="编辑商品信息" :close-on-click-modal="false">
      <el-form label-width="10em">
        <el-form-item label="关联商家">
          <el-tag v-for="(i, n) in editInfoForm.businessList" :key="n" style="margin-right: 10px">{{
            i.businessTitle
          }}</el-tag>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
            action=""
            :show-file-list="false"
            :http-request="onImageUpload"
            :accept="$store.getters['upload/fileAccept'].image"
          >
            <img v-if="editInfoForm.imageUrl" :src="editInfoForm.imageUrl | thumbMedium" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="名称">
          <el-input
            v-model="editInfoForm.name"
            placeholder="请输入名称"
            maxlength="40"
            show-word-limit
            class="middleInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="售卖价格">
          <bm-inputPrice v-model="editInfoForm.salePrice"></bm-inputPrice>
        </el-form-item>
        <el-form-item label="原价">
          <bm-inputPrice v-model="editInfoForm.originalPrice" :min="editInfoForm.salePrice"></bm-inputPrice>
        </el-form-item>
        <el-form-item>
          <el-button @click="submitEditInfo" :disabled="!isReadySubmitEditInfo" type="primary">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="isShowEditFileDialog" title="编辑核销码文件" :close-on-click-modal="false">
      <div class="header">
        <span class="title">文件列表</span>
        <div class="uploader">
          <input type="file" :accept="$store.getters['upload/fileAccept'].excel" @input="onPickFile" />
          <el-button type="success" size="mini">上传新文件</el-button>
        </div>
      </div>
      <el-table :data="editFileForm.fileList" fit center>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="errorMessage" v-for="(i, n) in scope.row.remarkList" :key="n">
              <div class="row">行：{{ i.row }}</div>
              <div class="msg">{{ i.msg }}</div>
              <div v-if="i.code" class="code">错误码：{{ i.code }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="fileName" label="名称" align="center"> </el-table-column>
        <el-table-column label="上传时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column>
        <el-table-column prop="totalCount" label="核销码数量" align="center"> </el-table-column>
        <el-table-column prop="tenantId" label="状态" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" type="info">审核中</el-tag>
            <el-tag v-if="scope.row.status === 1" type="danger">失败</el-tag>
            <el-tag v-if="scope.row.status === 2" type="success">成功/待绑定</el-tag>
            <el-tag v-if="scope.row.status === 3">已绑定</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-button @click="submitEditFile" type="primary">完成</el-button>
    </el-dialog>

    <el-drawer title="选择商家" :visible.sync="isShowPickBusiness">
      <div style="margin-left: 20px">
        <label>商家名称</label>
        <el-input v-model="searchByName" placeholder="请输入商家名称" style="width: 300px; margin-left: 10px">
          <el-button slot="append" icon="el-icon-search" @click="handleSearchBussiness"></el-button>
        </el-input>
      </div>
      <div class="businessList">
        <div class="item" v-for="(i, n) in businessList" :key="n" @click="handleAddBusiness(i)">
          <div class="logo" v-bg="i.businessLogo"></div>
          <div class="name">{{ i.businessTitle }}</div>
          <div class="area">{{ i.businessDistrictTitle }}</div>
        </div>
      </div>
      <el-pagination
        @current-change="onBusinessPageChange"
        background
        layout="prev, pager, next"
        :total="businessListPage.total"
        style="text-align: center; margin-top: 5px"
      ></el-pagination>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import tableMixin from '@/mixin/table'

export default {
  mixins: [tableMixin],
  data: () => ({
    filter: {
      id: '',
      name: ''
    },
    isShowAddDialog: false,
    addForm: {
      businessList: [],
      imageUrl: '',
      name: '',
      originalPrice: '',
      salePrice: '',
      file: null
    },
    isShowEditInfoDialog: false,
    editInfoForm: {
      id: '',
      imageUrl: '',
      name: '',
      originalPrice: '',
      salePrice: ''
    },
    isShowEditFileDialog: false,
    editFileForm: {
      id: '',
      fileList: []
    },
    businessList: [],
    businessListPage: {
      page: 1,
      size: 10,
      total: 0
    },
    isShowPickBusiness: false,
    searchByName: ''
  }),
  computed: {
    ...mapGetters({}),
    isReadySubmitAdd() {
      if (
        !this.addForm.businessList ||
        !this.addForm.businessList.length ||
        !this.addForm.imageUrl ||
        !this.addForm.name ||
        !this.addForm.originalPrice ||
        !this.addForm.salePrice ||
        !this.addForm.file
      ) {
        return false
      } else {
        return true
      }
    },
    isReadySubmitEditInfo() {
      if (
        !this.editInfoForm.id ||
        !this.editInfoForm.imageUrl ||
        !this.editInfoForm.name ||
        !this.editInfoForm.originalPrice ||
        !this.editInfoForm.salePrice
      ) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {},
  methods: {
    ...mapActions({ uploadImage: 'upload/image' }),
    handleReset() {
      this.filter = {
        id: '',
        name: ''
      }
      this.handleSearch()
    },
    getList() {
      return this.$http.get('/boom-center-product-service/sysAdmin/checkCodePool', {
        params: { ...this.filter, ...this.page }
      })
    },
    handleDelete(val) {
      this.$http.delete(`/boom-center-product-service/sysAdmin/checkCodePool/${val.id}`).then(res => {
        this.$message.success('删除成功')
        this.init()
      })
    },
    onImageUpload({ file }) {
      this.uploadImage({ file, type: 'commodity', isCompress: false }).then(res => {
        this.addForm.imageUrl = res
      })
    },
    onExcelUpload(val) {
      if (val.size > 10240000) {
        this.$message.error('文件过大，大于10M，请分开上传')
        this.$nextTick(() => {
          this.$refs.addDialogUpload.clearFiles()
        })
        return
      }
      this.addForm.file = val.raw
    },
    onExcelRemove() {
      this.addForm.file = null
    },
    handleSubmitAdd() {
      const loading = this.$loading()
      let formData = new FormData()
      Object.keys(this.addForm).forEach(i => {
        if (i === 'businessList') {
          formData.append(
            'businessIdList',
            this.addForm.businessList.map(i => i.id)
          )
        } else {
          formData.append([i], this.addForm[i])
        }
      })
      this.$http
        .post('/boom-center-product-service/sysAdmin/checkCodePool', formData)
        .then(res => {
          this.$message.success('添加核销码成功！')
          this.isShowAddDialog = false
          this.init()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    handleAdd() {
      this.addForm = {
        businessList: [],
        imageUrl: '',
        name: '',
        originalPrice: '',
        salePrice: '',
        file: null
      }
      this.isShowAddDialog = true
      this.$nextTick(() => {
        this.$refs.addDialogUpload.clearFiles()
      })
    },
    handleEdit(command, val) {
      this[command](val)
    },
    handleEditInfo(val) {
      if (!val.id) return
      this.$http.get(`/boom-center-product-service/sysAdmin/checkCodePool/${val.id}`).then(res => {
        this.editInfoForm = { ...res }
        this.isShowEditInfoDialog = true
      })
    },
    submitEditInfo() {
      const loading = this.$loading()
      let formData = new FormData()
      Object.keys(this.editInfoForm).forEach(i => {
        formData.append([i], this.editInfoForm[i])
      })
      this.$http
        .put('/boom-center-product-service/sysAdmin/checkCodePool', formData)
        .then(res => {
          this.$message.success('编辑商品信息成功！')
          this.isShowEditInfoDialog = false
          this.init()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    handleEditFile(val) {
      this.$http
        .get('/boom-center-product-service/sysAdmin/checkCodePool/checkCodeFileList', {
          params: {
            type: 1,
            size: 100,
            id: val.id
          }
        })
        .then(res => {
          this.editFileForm = {
            ...this.editFileForm,
            ...val,

            fileList: res.list.map(i => {
              i.remarkList = this.formatRemarkList(i.remark)
              return i
            })
          }

          this.isShowEditFileDialog = true
        })
        .catch(err => {
          this.$message.error('请求失败')
        })
    },
    formatRemarkList(val) {
      if (!val) {
        return []
      }
      try {
        let list = JSON.parse(val)
        return list.map(j => ({
          code: j.checkCode,
          row: j.rowNum,
          msg: j.errorMessage
        }))
      } catch (e) {
        return []
      }
    },
    onPickFile(e) {
      if (e.target.files.length) {
        const file = e.target.files[0]
        console.log(file)
        if (file.size > 10240000) {
          this.$message.error('文件过大，大于10M，请分开上传')
          return
        }
        console.log(file)
        const loading = this.$loading()
        let formData = new FormData()
        formData.append('file', file)
        this.$http
          .post(`/boom-center-product-service/sysAdmin/checkCodePool/import/${this.editFileForm.id}`, formData)
          .then(res => {
            this.$message.success('添加核销码文件成功！')
            this.isShowEditFileDialog = false
            this.init()
          })
          .catch(err => {
            this.$message.error(err.msg)
          })
          .finally(() => {
            loading.close()
          })
      }
    },
    submitEditFile() {
      this.isShowEditFileDialog = false
      this.init()
    },
    handleUpdateCheckedCode(val) {
      this.$router.push(`/product/partnerProduct/checkCode/checkList?id=${val.id}`)
    },
    getBusinessList() {
      this.$http
        .get('/boom-center-product-service/sysAdmin/business/list/mini', {
          params: {
            searchByName: this.searchByName,
            ...this.businessListPage
          }
        })
        .then(res => {
          this.businessList = res.list
          this.businessListPage.total = res.total
        })
    },
    onBusinessPageChange(val) {
      this.businessListPage.page = val
      this.getBusinessList()
    },
    handleAddBusiness(val) {
      if (!this.addForm.businessList.find(i => i.id === val.id)) {
        this.addForm.businessList.push({
          id: val.id,
          name: val.businessTitle,
          logo: val.businessLogo
        })
        this.isShowPickBusiness = false
      } else {
        this.$message.error('所选商家已存在')
      }
    },
    onBusinessTagClose(val) {
      const n = this.addForm.businessList.findIndex(i => i.id === val.id)
      if (n >= 0) {
        this.addForm.businessList.splice(n, 1)
      }
    },
    handleSearchBussiness() {
      this.businessListPage.page = 1
      this.getBusinessList()
    }
  },
  mounted() {
    this.getBusinessList()
  }
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
.fileList {
  text-align: center;
  .fileItem {
    width: 100%;
    display: flex;
    .column {
      flex-basis: 25%;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 5px;
      border-left: 1px solid var(--border);
      border-top: 1px solid var(--border);
      &:last-child {
        border-right: 1px solid var(--border);
      }
    }
    &:last-child {
      border-bottom: 1px solid var(--border);
    }
    &.header {
      background: var(--background);
      line-height: 30px;

      .column {
        padding: 0;
      }
    }
  }
  .tips {
    border: 1px solid var(--border);
  }
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .uploader {
    position: relative;
    right: 0;
    input {
      .wrap;
      width: 100%;
      opacity: 0;
      position: absolute;
    }
  }
}

.uploader {
  position: relative;
  right: 0;
  input {
    .wrap;
    width: 100%;
    opacity: 0;
    position: absolute;
  }
}

.errorMessage {
  display: flex;
  .row,
  .code {
    width: 100px;
  }
  .msg {
    flex-grow: 1;
    margin: 0 10px;
  }
}

.businessList {
  height: calc(~'100vh - 150px');
  overflow-x: hidden;
  overflow-y: auto;
  .item {
    .radius;
    background: var(--background);
    padding: 10px;
    margin: 10px;
    display: flex;
    align-items: center;
    .logo {
      .square(60px);
      flex-shrink: 0;
    }
    .name {
      margin: 0 10px;
      font-size: 18px;
      flex-grow: 1;
      flex-shrink: 0;
    }
    .area {
      color: var(--text-grey);
      flex-shrink: 0;
    }
  }
}
</style>
